import { type LanguageKey, type StyleText } from '#types/lokalisation'

export function getLokalisedString(
	valueObj: StyleText,
	locale: string,
): string {
	// Always return Danish unless explicitly asked for English
	if (locale && locale.split('-')[0] === 'en') {
		return valueObj['en'] || valueObj['da-DK'] // Fallback to Danish if English is missing
	}
	return valueObj['da-DK']
}

export function getFullLanguageName(language: string): string {
	// Return the full name of the language, defaulting to Danish
	if (language === 'en') return 'English'
	return 'Dansk'
}

export function getLanguageShortHand(language: string): string {
	// Always default to Danish shorthand unless explicitly English
	if (language && language.slice(0, 2).toLowerCase() === 'en') {
		return 'en'
	}
	return 'da'
}

export function getLanguageFromShortHand(language: string): LanguageKey {
	// Convert language shorthand to full key, defaulting to Danish
	if (language && language.split('-')[0] === 'en') {
		return 'en'
	}
	return 'da'
}

export function getLanguageFromCookieOrBrowserOrFallback(
	cookieLanguage: string | undefined | null,
	request: Request,
): string {
	// Only use the cookie language if it's explicitly set to English
	if (cookieLanguage && isAcceptedLanguage(cookieLanguage)) {
		return getLanguageShortHand(cookieLanguage)
	}

	// Always fallback to Danish
	return 'da'
}

export function isAcceptedLanguage(language: string): boolean {
	const acceptedLanguages = ['da', 'en'] // Prioritize Danish
	const lang = language.split('-')[0]
	return acceptedLanguages.includes(lang)
}
